.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

}

  .modal-main {
    position:fixed;
    background: white;
    width: auto;
    height: auto;
    top:50%;
    left:50%;
    padding: 20px;
    transform: translate(-50%,-50%);
    border-radius: 10px;
  }

  .display-block {
    display: block;
    transition: ease-in;
    -webkit-transition: ease-in;
    -moz-transition: ease-in;
    -ms-transition: ease-in;
    -o-transition: ease-in;
}

  .display-none {
    display: none;
    /*transition: ease-in-out(15);*/
    /*-webkit-transition: ease-in-out(15);*/
    /*-moz-transition: ease-in-out(15);*/
    /*-ms-transition: ease-in-out(15);*/
    /*-o-transition: ease-in-out(15);*/
}

